.contact-container {
  padding-bottom: 20px;
}

.contact-content {
  max-width:450px;
  margin: 0 auto;
}

.social-links {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 20px;
}

.social-links a {
  font-size: 2rem;
  margin: 0 10px;
  color: #333;
  transition: color 0.3s ease; 
}

.social-links a:hover {
  color: #9ce39d;
}

.contact-form {
  max-width: 600px;
  margin: 0 auto;
}

.contact-form label {
  font-size: 1rem;
  font-weight: bold; 
  margin-bottom: 5px;
  padding: 10px;
  display: flex; 
}

.contact-form input,
.contact-form textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
  outline: none;
}

.contact-form textarea {
  resize: vertical;
}

.contact-form button {
  background-color: #9ce39d;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease; 
}

.contact-form button:hover {
  background-color: rgb(35, 83, 21); 
}
