.company, .company2, .company3 {
    padding: 2px; 
    padding-bottom: 2rem;
    position: relative; 
  }
  
  .company {
    background: linear-gradient(rgba(219, 193, 193, 0.4),  #1877F2);
  }
  
  .company2 {
    background: linear-gradient(rgba(219, 193, 193, 0.4),  #149a60);
  }
  .company3{
    background: linear-gradient(rgba(219, 193, 193, 0.4),  #f87918);
  }
.company img, .company2 img, .company3 img{
    border-radius: 50%;
    overflow: hidden;
    width: 130px;
    object-fit: cover; 
    position: relative; 
    box-shadow: 0 .5rem 1rem #0000004d;
    height: 7rem;
    left: 0;
    margin-left: auto;
    margin-right: auto;
    object-fit: cover;
    position: absolute;
    right: 0;
    top: 3rem;
}

.cardd {
    background-color: #f0f0f0;
    border: 1px solid #ddd;
    border-radius: 8px;
    border: 1px solid hsla(0, 9%, 39%, 0.397);
    width: 35%;
    box-sizing: border-box;
    text-align: center;
    margin-bottom: 50px;
    cursor: pointer;  
  }

  .comp-prof{
    font-weight: 700;
    line-height: normal;
    margin: 0;
    padding-top: 2.5rem;
    text-align: center;
  }
  .cardd a{
    text-decoration: none;
    color: black;
  }

  .card a:hover{
    color:#9ce39d;
  }
 
  .comp-data{
    padding: 0 10px 10px 10px;
  }