.navbar {
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
  }
  
  .navbar-left {
    color: black;
    font-size: 45px;
    font-weight: bold;
  }
  
  .navbar-middle {
    display: flex;
  }
  
  .navbar-middle ul {
    list-style-type: none;
    margin-left: 500px;
    padding: 0;
    display: flex;
    font-size: 25px;
    font-weight: 500;
  }
  
  .navbar-middle ul li {
    margin-left: 20px;
  }
  
  .navbar-middle ul li a {
    display: block;
    color: black;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
  }
  
  .navbar-middle ul li a:hover {
    
  }
  
  .navbar-right {
    display: flex;
    align-items: center;
    font-size: 25px;
    font-weight: 500;
  }
  
  .contact-button {
    background-color: #9ce39d;
    color: black;
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 50px;
  }
  
  .contact-button:hover {
    background-color: #45a049;
  }
  